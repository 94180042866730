import { EventBus } from "../app";
import { Modal } from 'bootstrap';
import $ from 'jquery';

$(function () {
    const miniCartModal = new Modal(document.querySelector('#minicart-modal'));

    // когда в мини-корзине изменился товар - покажем ее.
    EventBus.$on('onMiniCartUpdated', () => {
        miniCartModal.show();
    });
});