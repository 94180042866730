import Swiper, { Navigation, Pagination, Grid, Autoplay, Thumbs } from "swiper";

new Swiper(".mainSwiper", {
    modules: [ Navigation, Pagination, Autoplay ],
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    autoplay: {
        delay: 4000,
    },
    navigation: {
        nextEl: ".main-swiper-button-next",
        prevEl: ".main-swiper-button-prev",
    },
});

new Swiper(".hitsSwiper", {
    slidesPerView: 4,
    spaceBetween: 50,
    slidesPerGroup: 4,
    loop: true,
    loopFillGroupWithBlank: true,

    navigation: {
        nextEl: ".hits-swiper-button-next",
        prevEl: ".hits-swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 50,
        },
        1400: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 50,
        },
    },
});

new Swiper(".category-swiper", {
    modules: [ Navigation, Pagination ],
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    navigation: {
        nextEl: ".category-swiper-button-next",
        prevEl: ".category-swiper-button-prev",
    },
});

const productViewSwiperThumbs = new Swiper('.productViewSwiperThumbs', {
    modules: [Navigation ],
    direction: "vertical",
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
    loop: true,
    navigation: {
        nextEl: ".productViewSwiperThumbs-swiper-button-next",
        prevEl: ".productViewSwiperThumbs-swiper-button-prev",
    },
    breakpoints: {
        300: {
            direction: "horizontal",
            slidesPerView: 2,
            slidesPerGroup: 2,
        },
        350: {
            direction: "horizontal",
            slidesPerView: 3,
            slidesPerGroup: 3,
        },
        576: {
            direction: "horizontal",
            slidesPerView: 4,
            slidesPerGroup: 4,
        },
        992: {
            direction: "vertical"
        },
    },
});

new Swiper(".productViewSwiper", {
    modules: [Thumbs],
    thumbs: {
        swiper: productViewSwiperThumbs,
    }
});

new Swiper(".cartSwiper", {
    modules: [Navigation, Pagination, Grid],
    slidesPerView: 4,
    spaceBetween: 35,
    slidesPerGroup: 4,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
        nextEl: ".product-view-swiper-button-next",
        prevEl: ".product-view-swiper-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 50,
        },
        1400: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 35,
        },
    },
});

new Swiper(".product-view-bottomSwiper", {
    modules: [Navigation, Pagination, Grid],
    slidesPerView: 4,
    spaceBetween: 35,
    slidesPerGroup: 4,
    loop: true,
    loopFillGroupWithBlank: true,
    navigation: {
        nextEl: ".product-view-swiper-bottom-button-next",
        prevEl: ".product-view-swiper-bottom-button-prev",
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
            slidesPerGroup: 1,
            spaceBetween: 20,
        },
        768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 50,
        },
        1400: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 35,
        },
    },
});