import $ from 'jquery';

$(function () {
    let modificationId = 0;
    $(document).on('click', '.app-product-view__modification', function (e) {
        e.preventDefault();
        const $container = $(this).closest('.app-product-view');
        const $scope = $(this);
        const $oldPrice = $container.find('.app-product-view__old-price');
        const $priceSale = $container.find('.app-product-view__price-sale');
        const oldPrice = $scope.data('old-price');
        const priceSale = $scope.data('price-sale-formatted');
        modificationId = $scope.data('modification-id');

        $container.find('.app-product-view__modification').removeClass('active');
        $scope.addClass('active');

        $container
            .find('.app-add-to-cart')
            .attr('data-modification-id', modificationId)
            .data('modification-id', modificationId);

        if (oldPrice > 0) {
            $oldPrice.removeClass('d-none').html($scope.data('old-price-formatted') + ' ₽');
        } else {
            $oldPrice.addClass('d-none');
        }
        $priceSale.html(priceSale + ' ₽');
    });
});