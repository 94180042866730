import appProductCountSelector from './app-product-count-selector';
import onUpdateCartCounter  from "./js/services/update-cart-counter";
import addProductToCart from './js/services/add-to-cart';
import checkModificationsCount from "./js/services/check-modifications-count";
import cartApp from './js/components/cart'
import filtersApp from './js/components/filters'
import createOrderFromProduct from "./js/components/createOrderFromProduct";
import productsModificationRenderer from "./js/services/products-modification-renderer";
import users from './js/services/users';

function onAddToCartSuccessHandler($scope) {
    $scope.data('product-count', 1);
    $('.app-product-count-selector-' + $scope.data('product-id') + ' input').val(1);
    setTimeout(() => {
        $scope.prop('disabled', '');
    }, 1500);
}

/**
 * Функция с доп. функционалом для интернет-магазина.
 */
function productApp() {
    $(function () {
        const $modificationsModalNode = document.getElementById('app-modifications-modal');
        const $miniCartModalNode = document.getElementById('minicart-modal');

        let $modificationsModal = null;
        if ($modificationsModalNode !== null) {
            $modificationsModal = new Modal($modificationsModalNode);
        }
        let $miniCartModal = null;
        if ($miniCartModalNode != null) {
            $miniCartModal = new Modal($miniCartModalNode);
        }


        $(document).on('click', '.app-add-to-cart', function (e) {
            e.preventDefault();

            const $scope = $(this);
            $scope.attr('disabled', 'disabled');

            const productId = parseInt($scope.data('product-id'), 10);
            const productModificationId = parseInt($scope.data('modification-id') || 0, 10);
            const productCount = parseInt($scope.data('product-count') || 1, 10);

            if (productModificationId === 0) {
                // посмотрим, есть ли у товара модификации. Если у товара есть модификации,
                // сначала мы должны показать окно с выбором модификаций
                checkModificationsCount(productId)
                    .then((count) => {
                        if (count === 0) {
                            // модификаций нет, можно смело добавлять товары в корзину
                            addProductToCart(
                                productId,
                                productModificationId,
                                productCount
                            ).then(() => {
                                onAddToCartSuccessHandler($scope);
                                if ($modificationsModal !== null) {
                                    $modificationsModal.hide();
                                }
                                if ($miniCartModal != null) {
                                    $miniCartModal.show();
                                }
                            });
                        } else {
                            // есть модификации, ничего не делаем, просто вызываем событие,
                            // что нужно сначала указать модификацию товара, а потом уже добавлять его в корзину
                            console.log('Требуется выбор модификации');
                            if ($modificationsModal !== null) {
                                // отрендерим список модификаций и покажем модальное окно с их выбором
                                productsModificationRenderer(productId)
                                    .then(() => {
                                        $modificationsModal.show();
                                    });
                            } else {
                                // так как модального окна нет с выбором модификации, отправим событие
                                console.log('Нет модального окна с выбором модификации. Ручной режим выбора.');
                                if (window.EventBus !== undefined) {
                                    window.EventBus.$emit('onCartShowModificationsList', {
                                        productId
                                    });
                                }
                            }
                        }
                    });
            } else {
                // у товара уже есть модификация, значит такой товар просто добавим в корзину
                addProductToCart(
                    productId,
                    productModificationId,
                    productCount
                ).then(() => {
                    onAddToCartSuccessHandler($scope);
                    if ($modificationsModal !== null) {
                        $modificationsModal.hide();
                    }
                    if ($miniCartModal != null) {
                        $miniCartModal.show();
                    }
                });
            }
        });

        $(document).on('click', '.app-add-to-favorite', function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $scope = $(this);
            const productId = $(this).data('product-id');

            $.request('onProductFavoriteToggle', {
                method: 'POST',
                loading: $.oc.stripeLoadIndicator,
                data: {
                    id: productId,
                }
            }).then((response) => {
                if (response.data.is_favorite) {
                    $scope.addClass('active');
                } else {
                    $scope.removeClass('active');
                }
                $('#app-total-favorites').html(response.data.count);
            });
        });

        cartApp();
        filtersApp();
        createOrderFromProduct();
        appProductCountSelector();
        users();
    });
}

export {
    onUpdateCartCounter,
    productApp
}