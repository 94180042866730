import $ from 'jquery';
import 'bootstrap';
import './utils/sliders';
import './utils/callback';
import './utils/products';
import './utils/product-modification';
import './utils/cart';
import Vue from 'vue';
import Inputmask from "inputmask";
import { productApp } from "../../../../../../plugins/infocity/shop/assets/app";
import Alert from "./ui/Alert";

// эта настройка нужна для корректной работы $ вне этого приложения
window.$ = $;
window.jQuery = $;

export const EventBus = new Vue();


require('@fancyapps/fancybox');

$(function () {
    const mask = new Inputmask('+7 (999) 999-99-99');
    mask.mask(document.querySelectorAll('.maskedinput-phone'));

    // fancybox
    $('[data-fancybox="gallery"]').fancybox();

    let url = window.location.pathname;
    url = url.split('/');
    url = '/' + url[1];
    $('.nav-item a[href="' + url + '"]').addClass('active');

    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $('.scrollup').removeClass('d-none');
        } else {
            $('.scrollup').addClass('d-none');
        }
    });

    $('.scrollup').click(function () {
        $("html, body").animate({
            scrollTop: 0
        }, 600);
        return false;
    });

    EventBus.$on('onCartShowModificationsList', () => {
        Alert.error('Пожалуйста, выберите размер');
    });

    productApp();
});