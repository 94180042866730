import $ from 'jquery';
import { Modal } from 'bootstrap';

$(function () {
    const $quickViewModalEl = document.querySelector('#quickProductViewModal');
    const $quickViewModal = new Modal($quickViewModalEl);

    $('.app-quick-product-view').on('click', function (e) {
        e.preventDefault();
        $.request('quickProductView::onView', {
            loading: $.oc.stripeLoadIndicator,
            data: {
                id: $(this).data('product-id')
            }
        }).then(() => {
            $quickViewModal.show();
        });
    });
});