import Swal from "sweetalert2";

export default class {
    static error(text) {
        Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: text
        });
    }

    static success(text) {
        Swal.fire({
            icon: 'success',
            title: 'Успешно',
            text: text
        });
    }
};